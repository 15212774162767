<template>
  <div>
    <page-main v-if="tabList.length !== 0" class="content">
      <el-tabs v-model="activeName">
        <template v-for="item in tabList">
          <el-tab-pane
            :key="item.name"
            :label="item.label"
            :name="item.name"
          >
            <el-badge
              v-if="item.name === 'data-service-center'"
              slot="label"
              :is-dot="isUnread"
              class="is-dot"
            >
              数据服务中心
            </el-badge>
            <component :is="item.componentsName" :is-unread.sync="isUnread" />
          </el-tab-pane>
        </template>
      </el-tabs>
    </page-main>
    <page-main class="content">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="帮助中心" name="help-center" class="help-center">
          <el-row
            v-if="activeTab === 'help-center'"
            align="middle"
            type="flex"
            class="more-btn"
          >
            <span class="text-info-link" @click="onJump">查看更多</span>
            <i class="el-icon-arrow-right text-info-link" />
          </el-row>
          <div v-loading="loading" class="default-height">
            <help-item
              v-for="item in indexHelpList"
              :key="item.id"
              :item="item"
              @onRead="onReadHelp"
              @onJump="onJumpHelpDetail"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="indexTopMessage.length > 0" label="产品动态" name="topMessage">
          <el-row
            v-for="item in indexTopMessage"
            :key="item.id"
            type="flex"
            justify="space-between"
            align="middle"
            class="message-item"
          >
            <el-row type="flex" align="middle">
              <i class="el-icon-message-solid tip-ic" />
              <span class="top-text" @click="onJumpHelpDetail(item.id)">{{ item.title }}</span>
            </el-row>
            <el-row align="middle" type="flex">
              <span class="color-assist">{{ item.publishAt }}</span>
              <el-link
                :underline="false"
                type="primary"
                class="p-margin"
                @click="onJumpTop"
              >
                更多
              </el-link>
            </el-row>
          </el-row>
        </el-tab-pane>

        <el-tab-pane name="message" label="消息管理">
          <el-badge
            v-if="unreadCount > 0"
            slot="label"
            :value="unreadCount"
            :max="99"
            class="is-dot"
          >
            消息管理
          </el-badge>
          <div class="lay-box">
            <MessageChild v-if="activeTab === 'message'" @onUpdateCount="getUnreadCount" />
          </div>
        </el-tab-pane>
      </el-tabs>
    </page-main>
    <BindMobileModal :visible="showMobileVisilbe" @onClose="onCloseModal" />
    <BindWechatModal :visible="showWechatVisilbe" @onClose="onCloseModal" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import MessageChild from './IndexChildMessage'
import HelpItem from '@/bizComponents/HelpItem'
import BindMobileModal from '@/bizComponents/BindMobileModal'
import BindWechatModal from '@/bizComponents/BindWechatModal'
import { getCookie, getPersonInfo, getUserInfo } from '@/utils/auth'
import DataServiceCenter from './DataServiceCenter.vue'
import DataCockpit from './DataCockpit.vue'
import AcademicTaskFollow from './AcademicTaskFollow.vue'
import { isMobile } from '@/utils/util.js'
import {setUserType} from '@/utils/ali-log'
export default {
  name: 'Index',
  components: {
    MessageChild,
    HelpItem,
    BindMobileModal,
    BindWechatModal,
    DataServiceCenter,
    DataCockpit,
    AcademicTaskFollow
  },
  data() {
    return {
      activeTab: 'help-center', // message or download
      loading: false,
      showMobileVisilbe: false, // 绑定手机号弹窗
      showWechatVisilbe: false, // 绑定微信号弹窗
      isCollapse: false,
      activeName: 'data-cockpit',
      isUnread: false,
      isMobile: isMobile(),
      unreadCount: 0
    }
  },
  computed: {
    ...mapGetters(['indexHelpList', 'indexTopMessage']),
    tabList() {
      let baseTab = [
        {
          label: '学术任务跟进',
          name: 'academic-task-follow',
          auth: 'academicTaskFollow',
          componentsName: 'AcademicTaskFollow'
        },
        {
          label: '数据驾驶舱',
          name: 'data-cockpit',
          auth: 'home.dataCockpit',
          componentsName: 'DataCockpit'
        }, {
          label: '数据服务中心',
          name: 'data-service-center',
          auth: 'dataService',
          componentsName: 'DataServiceCenter'
        }
      ]
      baseTab = baseTab.filter(item => this.$auth(item.auth))
      return baseTab
    }
  },
  mounted() {
    this.activeName = this.tabList[0]?.name || ''
    this.init()
    // 检查绑定手机号和微信号
    this.initCheck()
    this.initTop()
    this.setOrgType()
    this.getUnreadCount()
  },
  methods: {
    ...mapActions(['getIndexHelpMessageList', 'getIndexTopMessage', 'onReadHelp', 'getFactoryAccountList']),
    setOrgType() {
      if (getPersonInfo().accountNum && getPersonInfo().personToken) {
        const req = {
          personToken: getPersonInfo().personToken
        }
        this.getFactoryAccountList(req).then(
          res => {
            res.map(item => {
              if (item.current) {
                const orgName = item.topOrgName
                if (orgName === '药脉通测试公司' ||
                orgName === '长卿测试公司' ||
                orgName === '甘松测试公司' ||
                orgName === '药脉通演示账号（总厂家') {
                  setUserType('testOrg')
                } else {
                  setUserType('normalOrg')
                }
              }
            })
          },
          rea => {
            this.$message.error(rea.message)
          }
        )
      } else {
        const orgName = getPersonInfo().orgLoginVo.orgName
        if (orgName === '药脉通测试公司' ||
        orgName === '长卿测试公司' ||
        orgName === '甘松测试公司' ||
        orgName === '药脉通演示账号（总厂家）') {
          setUserType('testOrg')
        } else {
          setUserType('normalOrg')
        }
      }
    },
    initTop() {
      this.getIndexTopMessage({ pageSize: 1, categoryName: '产品动态' })
    },
    init() {
      this.loading = true
      this.getIndexHelpMessageList({ pageSize: 6, categoryName: '常见问题' }).then(
        () => {
          this.loading = false
        },
        () => {
          this.loading = false
        }
      )
    },
    getUnreadCount() {
      this.$axios.get(this.$API.getUnreadCount).then(
        res => {
          this.unreadCount = res.data
        },
        () => {
          this.unreadCount = 0
        }
      )
    },
    onJump() {
      const url = this.$router.resolve('/help/list?name=常见问题')
      window.open(url.href, '_blank')
    },
    onJumpTop() {
      const url = this.$router.resolve('/help/list?name=产品动态')
      window.open(url.href, '_blank')
    },
    onJumpHelpDetail(id = '') {
      const url = this.$router.resolve(`/help/detail/${id}`)
      window.open(url.href, '_blank')
    },
    initCheck() {
      if (this.isMobile) return
      const login = getUserInfo().login
      // 弹窗 一次登录只展示一次
      if (window.localStorage.getItem('showBindModal')) return
      // 绑定手机号和微信弹窗 一个月两次
      const data = getPersonInfo()
      const bindModalCount = getCookie(`${login}_bindModal`) || 0
      if (bindModalCount < 2) {
        this.showMobileVisilbe = !data.mobile
        this.showWechatVisilbe = !data.openId && !this.showMobileVisilbe
      }
    },
    onCloseModal() {
      this.showMobileVisilbe = false
      this.showWechatVisilbe = false
    }
  }
}
</script>
<style lang="scss" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
.top-text {
  color: #272e40;
  cursor: pointer;
  &:hover {
    color: #3d61e3;
  }
}
.tip-ic {
  color: #c8c9ce;
  font-size: 20px;
  margin-right: 15px;
}
.default-height {
  min-height: 300px;
}
.title-box {
  padding-top: 10px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(233, 234, 235, 1);
}
.message-item {
  height: 53px;
  line-height: 53px;
  border-bottom: 1px solid #e9eaeb;
  &:nth-child(1) {
    border-top: 1px solid #e9eaeb;
  }
}
.help-item {
  line-height: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(233, 234, 235, 1);
}
.p-margin {
  margin-left: 12px;
}
.content {
  flex: 1;
  padding-top: 0;
  ::v-deep .el-tabs {
    .el-tabs__header {
      .el-tabs__nav-wrap {
        overflow: inherit;
        &::after {
          width: calc(100% + 2 * #{$g-main-wrap-padding});
          left: -$g-main-wrap-padding;
          height: 1px;
        }
      }
    }
    .el-tabs__content {
      overflow: inherit;
      .help-center {
        position: relative;
        .more-btn {
          position: absolute;
          right: 0;
          top: -35px;
        }
      }
    }
  }
}
.is-dot {
  ::v-deep .el-badge__content.is-fixed {
    top: 12px;
    right: 0;
  }
}
</style>
