<template>
  <el-collapse v-model="active" accordion @change="onRead">
    <el-collapse-item :name="item.itemId">
      <template slot="title">
        <el-row
          type="flex"
          justify="space-between"
          class="item-w text-ellipsis"
          :class="{ active: active }"
        >
          <el-row type="flex" align="middle">
            <el-tag
              v-if="!item.read"
              size="mini"
              style="background: #ff9b00; border: none; color: #fff; line-height: 20px;"
            >
              未读
            </el-tag>
            <el-tag v-else size="mini" type="info">已读</el-tag>
            <span class="item-title">{{ itemData.title }}</span>
          </el-row>
          <span class="color-assist" :class="{ active: active }">{{ item.dateTime }}</span>
        </el-row>
      </template>
      <div class="item-wrapper" :class="{ active: active }">
        <div>{{ itemData.mainText }}</div>
        <el-row>
          <span>{{ itemData.assistText }}</span>
          <el-link type="primary" :underline="false" @click="itemData.func">{{ itemData.activeText }}</el-link>
        </el-row>
        <div>{{ itemData.descText }}</div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>
<script>
export default {
  name: 'PlatformItem',
  props: {
    item: {
      type: Object,
      default() {
        return {
        }
      }
    }
  },
  data() {
    return {
      active: ''
    }
  },
  computed: {
    itemData() {
      let output = null
      const item = this.item
      const staticData = {
        meeting_publish: {
          title: `科会：${item.itemName}正式发布`,
          mainText: `科会资源投入：${item.count / (item.unitCount || 1)}个`,
          assistText: '分配详情：',
          activeText: '点击查看详情',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        meeting_append: {
          title: `科会：${item.itemName}资源追加`,
          mainText: `科会资源追加：${item.count / (item.unitCount || 1)}个`,
          assistText: '分配详情：',
          activeText: '点击查看详情',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        meeting_recall: {
          title: `科会：${item.itemName}资源撤回`,
          assistText: '撤回详情：',
          activeText: '点击查看详情',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        freeze: {
          title: `员工管理：冻结${item.itemName}`,
          mainText: `${item.itemName}账号被冻结！`,
          descText: `操作人：${item.userName || ''}`,
          assistText: '',
          activeText: '',
          func: () => {
          }
        },
        normal: {
          title: `员工管理：解冻${item.itemName}`,
          mainText: `${item.itemName}账号已解冻！`,
          descText: `操作人：${item.userName || ''}`,
          assistText: '',
          activeText: '',
          func: () => {
          }
        },
        transform: {
          title: `员工管理：市场交接${item.fromName}的医生交接给${item.toName}`,
          assistText: '市场交接详情：',
          activeText: '点击查看',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        auth: {
          title: `权限管理：授权${item.itemName}${item.toName}`,
          mainText: `授予${item.itemName}的角色！`,
          assistText: '',
          activeText: '',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
          }
        },
        auth_change: {
          title: `权限管理：变更权限${item.fromName}${item.toName}`,
          mainText: `变更${item.itemName}的${item.fromName}为${item.toName}`,
          assistText: '',
          activeText: '',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
          }
        },
        recharge: {
          title: `账户管理：账户充值${item.count}积分`,
          mainText: `厂家账户充值${item.count}积分`,
          assistText: '',
          activeText: '',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
          }
        },
        balance_remind: {
          title: `账户管理：厂家账户积分数低于${item.count}积分`,
          mainText: `厂家账户积分数低于${item.count}积分，请尽快充值！`,
          assistText: '',
          activeText: '',
          descText: '',
          func: () => {
          }
        },
        survey_recall: {
          title: `调研撤回：${item.itemName}撤回`,
          assistText: '撤回详情：',
          activeText: '点击查看详情',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        survey_publish: {
          title: `调研发布：${item.itemName}发布`,
          mainText: `${item.count / (item.unitCount || 1)}个`,
          assistText: '分配详情：',
          activeText: '点击查看详情',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        survey_append: {
          title: `调研：${item.itemName}资源追加`,
          mainText: `${item.count / (item.unitCount || 1)}个`,
          assistText: '分配详情：',
          activeText: '点击查看详情',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        visit_recall: {
          title: `拜访撤回：${item.itemName}撤回`,
          assistText: '撤回详情：',
          activeText: '点击查看详情',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        visit_publish: {
          title: `拜访发布：${item.itemName}发布`,
          mainText: `拜访资源投入：${item.count || 1}个`,
          assistText: '分配详情：',
          activeText: '点击查看详情',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        visit_expired_recall: {
          title: `拜访过期：${item.itemName}过期自动撤回`,
          assistText: '撤回详情：',
          activeText: '点击查看详情',
          func: () => {
            this.onCheck()
          }
        },
        DOCTOR_BATCH_BIND_REPRESENT: {
          title: `医生管理：代表${item.toName}绑定了${item.count}位医生`,
          assistText: '医生绑定详情：',
          activeText: '点击查看',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        PARENT_ORG_DEL_CHILD_ORG_RESOURCE: {
          title: '活动管理：上级组织删除了您的素材',
          assistText: `素材名称：${item.itemName}`,
          activeText: '',
          descText: `上级组织：${item.fromName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        TOP_ORG_DEL_CHILD_ORG_ACTIVITY: {
          title: '活动管理：上级组织删除了您的活动',
          assistText: `活动名称：${item.itemName}`,
          activeText: '',
          descText: `上级组织：${item.fromName || ''}`,
          func: () => {
            this.onCheck()
          }
        }
      }
      const sceneTitle = item.sceneType == 1 ? '科室会' : item.sceneType == 4 ? '问卷调研' : item.sceneType == 5 ? '直播' : item.sceneType == 6 ? '会议' : item.sceneType == 7 ? '代表培训' : item.sceneType == 8 ? '拜访' : item.sceneType == 12 ? '播客' : ''
      const staticText = {
        code_purchase: {
          title: `${sceneTitle}：${item.itemName} 购买码`,
          mainText: `${sceneTitle}资源投入：${item.count || 1}个`,
          assistText: '',
          // activeText: '点击查看详情',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        code_recall: {
          title: `${sceneTitle}：${item.itemName} 手动撤回码`,
          mainText: `${sceneTitle}资源撤回：${item.count || 1}个`,
          assistText: '',
          // activeText: '点击查看详情',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        code_expired_recall: {
          title: `${sceneTitle}：${item.itemName} 过期撤回码`,
          mainText: `${sceneTitle}资源撤回：${item.count || 1}个`,
          assistText: '',
          // activeText: '点击查看详情',
          descText: `操作人：${item.userName || '系统自动撤回'}`,
          func: () => {
            this.onCheck()
          }
        },
        company_code_recall_company: {
          title: `${sceneTitle}:公司撤回码`,
          mainText: `${sceneTitle}资源撤回：${item.count || 1}个`,
          assistText: '',
          activeText: '点击查看详情',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            this.onCheck()
          }
        },
        factory_be_recall: {
          title: `${sceneTitle}:厂家码被撤回`,
          mainText: `${sceneTitle}资源撤回：${item.count || 1}个`,
          assistText: '',
          activeText: '',
          descText: `操作人：${item.userName || ''}`,
          func: () => {
            // this.onCheck
          }
        },
        PARENT_ORG_ATTRIBUTE_CODE_TO_CHILD_ORG: {
          title: `活动管理：上级组织给您分配了${item.count}个任务`,
          assistText: '活动详情：',
          activeText: '点击查看',
          descText: `上级组织：${item.fromName || ''}`,
          func: () => {
            this.onCheck()
          }
        }
      }
      if (sceneTitle) {
        // 12月13日之后 新返回了sceneType 采用这边
        output = staticText[item.itemType] || {}
      } else {
        // 老数据处理
        output = staticData[item.itemType] || {}
      }
      return output
    }
  },
  methods: {
    onRead() {
      this.$emit('onRead', this.item.messageId)
    },
    onCheck() {
      this.$emit('onCheck')
    },
    onOpen() {
      this.onRead()
    }
  }
}
</script>
<style lang="scss" scoped>
.item-w {
  font-size: 14px;
  width: 100%;
  padding-left: 10px;
  padding-right: 35px;
  margin-right: -20px;
  color: #7784a1;
  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
  }
  &.active {
    background: #f6f7fb;
    color: #272e40;
  }
  .color-assist {
    &.active {
      color: #272e40;
    }
  }
}
.item-wrapper {
  font-size: 14px;
  padding-left: 75px;
  padding-bottom: 25px;
  margin-bottom: -25px;
  color: #272e40;
  &.active {
    background: #f6f7fb;
  }
}
.item-title {
  width: 100%;
  margin-left: 10px;
}
</style>
