<template>
  <div class="infinite-list-wrapper" style="overflow: auto;">
    <div
      v-infinite-scroll="onLoad"
      :infinite-scroll-disabled="disabled"
      infinite-scroll-distance="10"
    >
      <PlatformItem
        v-for="item in messageList"
        :key="item.messageId"
        :item="item"
        @onRead="onRead(item)"
        @onCheck="platformCheck(item)"
      />
    </div>
    <el-row v-if="loading" type="flex" justify="center">加载中...</el-row>
    <el-row v-if="isEnd" type="flex" justify="center">没有更多了</el-row>
    <MeetingAllotMessageModal
      :type="allotType"
      :title="allotTitle"
      :visible="showAllotModal"
      :item-id="itemId"
      @onClose="onCloseModal"
    />
    <ChangeMemberMessageModal
      :visible="showChangeModal"
      :item-id="itemId"
      :title="allotTitle"
      @onClose="onCloseModal"
    />
  </div>
</template>
<script>
import PlatformItem from '@/bizComponents/PlatformItem'
import MeetingAllotMessageModal from '@/bizComponents/MeetingAllotMessageModal'
import ChangeMemberMessageModal from '@/bizComponents/ChangeMemberMessageModal'
import { mapActions, mapGetters } from 'vuex'
import { inviteCodeType } from '@/utils/util'
export default {
  name: 'IndexChildMessage',
  components: {
    PlatformItem,
    MeetingAllotMessageModal,
    ChangeMemberMessageModal
  },
  data() {
    return {
      showAllotModal: false,
      isExport: false,
      loading: false,
      pageNo: 1,
      pageSize: 20,
      isEnd: false,
      totalCount: 0,
      itemId: '', // 弹窗的id
      allotTitle: '',
      allotType: '',
      showChangeModal: false
    }
  },
  computed: {
    ...mapGetters(['messageList']),
    disabled() {
      return this.loading || this.isEnd
    }
  },
  mounted() {
    this.onInit()
  },
  methods: {
    ...mapActions(['initMessageList', 'getMessageList', 'onReadMessage']),
    onRead(item) {
      if (item.read) return
      this.onReadMessage(item.messageId).then(() => {
        this.$emit('onUpdateCount')
      })
    },
    onLoad() {
      if (this.disabled) return
      this.loading = true
      this.getMessageList(this.pageNo).then(
        res => {
          this.pageNo++
          this.loading = false
          this.isEnd = !res.length
        },
        () => {
          this.loading = false
        }
      )
    },
    onInit() {
      if (this.loading) return
      this.loading = true
      this.isEnd = false
      this.pageNo = 1
      this.initMessageList(this.pageNo).then(
        res => {
          this.pageNo++
          this.loading = false
          this.isEnd = !res.length
        },
        () => {
          this.loading = false
        }
      )
    },
    platformCheck(item) {
      // todo
      let title = ''
      let type = ''
      const sceneType =
        item.sceneType == 1
          ? '科室会'
          : item.sceneType == 4
            ? '问卷调研'
            : item.sceneType == 5
              ? '直播'
              : item.sceneType == 6
                ? '会议'
                : item.sceneType == 12
                  ? '播客'
                  : item.sceneType == 7
                    ? '代表培训'
                    : item.sceneType == 8
                      ? '拜访'
                      : ''
      const titleData = {
        meeting_publish: '科会分配详情',
        meeting_append: '科会追加详情',
        meeting_recall: '科会撤回任务详情',
        survey_publish: '调研分配详情',
        survey_append: '调研追加详情',
        survey_recall: '调研撤回任务详情',
        visit_publish: '拜访分配详情',
        visit_recall: '拜访撤回任务详情',
        visit_expired_recall: '拜访撤回任务详情'
      }
      const titleText = {
        code_purchase: `${sceneType}设置任务详情`,
        code_recall: `${sceneType}手动撤回任务详情`,
        code_expired_recall: `${sceneType}过期任务详情`
      }
      if (sceneType) {
        // 12月13日之后 新返回了sceneType 采用这边
        title = titleText[item.itemType]
        type =
          item.itemType == 'code_purchase'
            ? '购买'
            : item.itemType == 'code_recall'
              ? '手动撤回'
              : item.itemType == 'code_expired_recall'
                ? '过期撤回'
                : ''
      } else {
        // 老数据还是这样子
        title = titleData[item.itemType]
        type = item.itemType.includes('publish')
          ? '分配'
          : item.itemType.includes('append')
            ? '追加'
            : item.itemType.includes('recall')
              ? '撤回'
              : ''
      }
      if (title && type) {
        this.showAllotModal = true
        this.allotTitle = title
        this.allotType = type
      }
      if (
        item.itemType === 'transform' ||
        item.itemType === 'DOCTOR_BATCH_BIND_REPRESENT'
      ) {
        this.showChangeModal = true
      }
      this.itemId = item.itemId
      if (item.itemType === 'PARENT_ORG_ATTRIBUTE_CODE_TO_CHILD_ORG') {
        const sceneTypeText = inviteCodeType(item.sceneType)[2]
        this.$router.push(`/academic/scene/${sceneTypeText}/rule/${this.itemId}?disabledBack=true`)
      }
    },
    onCloseModal() {
      this.showChangeModal = false
      this.showAllotModal = false
      this.allotType = ''
      this.allotTitle = ''
      this.itemId = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.infinite-list-wrapper {
  height: 500px;
  overflow: auto;
}
</style>
